<template>
  <div class="container butcher-shop">
    <div>
      <div v-if="languageCode('es')">
        <p>
          ¡Bienvenido a nuestra carnicería!
        </p>
        <p>
          En nuestro <router-link :to="{ name: 'concept' }">proyecto de restauración del ecosistema </router-link>
          trabajamos con varios animales. De veces en cuando podemos ofrecer algunos de ellos a la venta.
        </p>
      </div>
      <div v-else-if="languageCode('de')">
        <p>
          Willkommen in unserer Metzgerei!
        </p>
        <p>
          In unserem <router-link :to="{ name: 'concept' }">Projekt zur Wiederherstellung des Ökosystems
          </router-link> arbeiten wir mit einer Reihe von Tieren. Hier und da können wir einige davon zum Kauf anbieten.
        </p>
      </div>
      <div v-else>
        <p>
          Welcome to our butcher shop!
        </p>
        <p>
          In our <router-link :to="{ name: 'concept' }">ecosystem restoration project
          </router-link> we work with a number of animals. Here and there we can offer some of them for sale.
        </p>
      </div>
      <div class="fresh-products">
        <router-link v-if="hasPorkMeatSales" :to="{ name: 'ibericoMeat' }">
          <figure>
            <img src="@/assets/images/pigOutline.jpg" />
            <figcaption>{{ $t('butcherShop.iberianPig') }}</figcaption>
          </figure>
        </router-link>
        <router-link v-if="hasBeefSales" :to="{ name: 'yourOwnCattle' }">
          <figure>
            <img src="@/assets/images/cowOutline.jpg" />
            <figcaption>Dehesa Cattle</figcaption>
          </figure>
        </router-link>
      </div>
    </div>
    <figure>
      <img src="@/assets/images/butcherShop.jpg" />
    </figure>
  </div>
</template>

<script>
import { getLanguage } from '@/library'
import { axiosInstance } from '@/library'
import { setupFeatureFlags } from '@/library'

export default {
  name: "ButcherShop",
  components: {
  },

  data: () => ({
    productsAvailable: [],
  }),

  setup() {
    return setupFeatureFlags()
  },

  beforeMount() {
    this.listProducts()
  },

  methods: {
    languageCode(code) {
      return code === getLanguage()
    },

    listProducts() {
      axiosInstance.get(`${window.VUE_APP_GRANJA}/api/sales/inventory`)
        .then(response => {
          if (response.status === 200) {
            this.productsAvailable = response.data.inventory
          }
        })
        .catch(console.error)
    },
  }

};
</script>
